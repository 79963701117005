// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-dos-calouros-js": () => import("./../../../src/pages/area-dos-calouros.js" /* webpackChunkName: "component---src-pages-area-dos-calouros-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kit-de-marca-js": () => import("./../../../src/pages/kit-de-marca.js" /* webpackChunkName: "component---src-pages-kit-de-marca-js" */),
  "component---src-pages-midia-js": () => import("./../../../src/pages/midia.js" /* webpackChunkName: "component---src-pages-midia-js" */),
  "component---src-pages-modalidades-js": () => import("./../../../src/pages/modalidades.js" /* webpackChunkName: "component---src-pages-modalidades-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-transparencia-js": () => import("./../../../src/pages/transparencia.js" /* webpackChunkName: "component---src-pages-transparencia-js" */)
}

