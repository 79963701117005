import React from "react"
import { Link } from "gatsby"
import navbar from "../data/navbar.json"
import { StaticImage } from "gatsby-plugin-image"

const Navbar = ({ isActive, toggleNavbar }) => {
  return (
    <nav
      className="navbar is-primary is-spaced pt-2 pb-0"
      aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand is-align-items-center">
          <Link
            to="/"
            className="navbar-item"
            style={{ backgroundColor: "transparent" }}>
            <StaticImage
              src="../images/logo.png"
              alt="logo"
              loading="eager"
              placeholder="blurred"
              layout="fixed"
              width={85}
              height={85}
              quality={100}
            />
          </Link>
          <a
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="nav-menu"
            onClick={toggleNavbar}
            style={{ backgroundColor: "transparent" }}>
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          id="nav-menu"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-start">
            <Link
              className="navbar-item has-text-weight-semibold"
              to={navbar.navigation.item1.url}
              style={{ backgroundColor: "transparent" }}>
              {navbar.navigation.item1.title}
            </Link>
            <Link
              className="navbar-item has-text-weight-semibold"
              to={navbar.navigation.item2.url}
              style={{ backgroundColor: "transparent" }}>
              {navbar.navigation.item2.title}
            </Link>
            <Link
              className="navbar-item has-text-weight-semibold"
              to={navbar.navigation.item3.url}
              style={{ backgroundColor: "transparent" }}>
              {navbar.navigation.item3.title}
            </Link>
            <Link
              className="navbar-item has-text-weight-semibold"
              to={navbar.navigation.item7.url}
              style={{ backgroundColor: "transparent" }}>
              {navbar.navigation.item7.title}
            </Link>
            <Link
              className="navbar-item has-text-weight-semibold"
              to={navbar.navigation.item4.url}
              style={{ backgroundColor: "transparent" }}>
              {navbar.navigation.item4.title}
            </Link>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item has-text-weight-semibold"
              href={navbar.navigation.item5.url}
              style={{ backgroundColor: "transparent" }}>
              {navbar.navigation.item5.title}
            </a>
            <div className="navbar-item">
              <a
                className="button has-text-weight-semibold is-dark"
                href={navbar.navigation.item6.url}>
                {navbar.navigation.item6.title}
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
